<template lang="pug">
.container
  h1.title.mt Tablero de estaciones
  b-message(v-if='ewsPendingCommands.length')
    span.mr-1 Hay {{ ewsPendingCommands.length }} {{ ewsPendingCommands.length === 1 ? 'comando' : 'comandos' }} en ejecución, use el ícono de comandos para más detalles
  .stations
    dashboard-card(:device='device' v-for='device in devices' :key='device.id' @open-telemetry='openTelemetry')
    b-modal(v-model='isTelemetryModalActive' has-modal-card :can-cancel='["x"]')
      modal-card(v-if='deviceSelected' :title='`Telemetría - ${deviceSelected.name}`')
        telemetry(:deviceId='deviceSelected.id')
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Telemetry from '@/components/devices/Telemetry'
import DashboardCard from './DashboardCard'
import { commandTypeEnum, devicePorpuseEnum } from '@/constants/enums'

export default {
  components: { Telemetry, DashboardCard },
  data () {
    return {
      deviceSelected: null,
      isTelemetryModalActive: false
    }
  },
  created () {
    this.getDevices({ porpuses: devicePorpuseEnum.EWS })
  },
  computed: {
    ...mapState({
      loading: state => state.devices.loading,
      error: state => state.devices.error,
      devices: state => state.devices.devices,
      pendingCommands: state => state.commands.pendingCommands
    }),
    ewsPendingCommands () {
      return this.pendingCommands.filter(command => [commandTypeEnum.OpenOutput, commandTypeEnum.CloseOutput].includes(command.type))
    }
  },
  methods: {
    ...mapActions({
      getDevices: 'devices/getDevices'
    }),
    openTelemetry (device) {
      this.deviceSelected = device
      this.isTelemetryModalActive = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/initial-variables";

.stations {
  margin-top: $gap;
  display: grid;
  column-gap: $gap / 2;
  row-gap: $gap / 2;
  grid-template-columns: repeat(4, 1fr);
}
</style>
